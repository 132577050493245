import { useEffect, useState } from 'react';

import { Addon } from '../../../api/addons';
import { PayOption } from '../../../api/paywall';
import Button from '../../../components/v2/Button/Button';
import ButtonPill from '../../../components/v2/ButtonPill';
import { Modal } from '../../../components/v2/Modal/Modal';
import { Typography } from '../../../components/v2/Typography/Typography';
import AddonCard from '../../../components/v2/UpsellCard/AddonCard';
import UpsellCard from '../../../components/v2/UpsellCard/UpsellCard';

import './UpsellModal.scss';

type UpsellModalType = {
  isUpsellModalOpen: boolean;
  setUpsellModalOpen: (value: boolean) => void;
  products: PayOption[];
  baseProduct: PayOption | null;
  setSelectedPlan: (value: PayOption | null) => void;
  handlePlaceOrder: (value: PayOption) => void;
  handleSkip: (value: boolean) => void;
  upsoldPlan: PayOption | null;
  setUpsoldPlan: (value: PayOption) => void;
  addons: Addon[];
  selectedAddons: string[];
  setSelectedAddons: (value: string[]) => void;
};

const UpsellModal = ({
  isUpsellModalOpen,
  setUpsellModalOpen,
  products,
  baseProduct,
  handlePlaceOrder,
  setSelectedPlan,
  handleSkip,
  upsoldPlan,
  setUpsoldPlan,
  addons,
  selectedAddons,
  setSelectedAddons,
}: UpsellModalType) => {
  const [upsell, setUpsell] = useState<PayOption | null>(null);
  const [originalDifference, setOriginalDifference] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [upsellSelected, setUpsellSelected] = useState<boolean>(false);

  //This triggers place order when upsoldPlan is set
  useEffect(() => {
    if (upsoldPlan) handlePlaceOrder(upsoldPlan);
  }, [upsoldPlan]);

  const attributes = {
    'The Modern Wedding': [
      'Unlimited Ceremony Generations',
      'Officiant Access & Support',
      'Unlimited Guest Toast Invites',
      'Wedding Planner Access',
    ],
    'The Grand Wedding': [
      'Unlimited Welcome Toast Generations',
      'Prioritized Customer Service',
    ],
  };

  useEffect(() => {
    if (baseProduct && products.length > 0) {
      chooseUpsell();
    }
  }, [baseProduct, products]);

  useEffect(() => {
    setDiscount(
      Math.floor(((originalDifference - 19.99) / originalDifference) * 100)
    );
  }, [originalDifference]);

  /**
   * This logic is frail because it uses the payplan names
   * To solve this we'll need to add identifiers and seed to existing data
   * This will also not work for older plans, but this is handled in PayPlans.tsx
   */
  const chooseUpsell = () => {
    let nextTierPlan = null;

    if (!baseProduct) return;

    if (baseProduct.name === 'Vows') {
      // nextTierPlan = products.find(
      //   (product) => product.name === 'The Modern Wedding'
      // );
      setUpsell(null);
      return;
    }

    if (baseProduct.name === 'The Modern Wedding') {
      nextTierPlan = products.find(
        (product) => product.name === 'The Grand Wedding'
      );
    }

    if (!nextTierPlan || baseProduct.name === 'The Grand Wedding') {
      // handleSkip(true);
      // setUpsellModalOpen(false);
      setUpsell(null);
      return;
    }

    setUpsell(nextTierPlan || null);

    setOriginalDifference(nextTierPlan?.price - baseProduct?.price || 0);
  };

  return (
    <Modal
      isOpen={isUpsellModalOpen}
      onClose={() => {
        // handleSkip(true);
        // handlePlaceOrder(baseProduct as PayOption);
        setUpsellModalOpen(false);
        setSelectedAddons([]);
        setSelectedPlan(baseProduct);
        setUpsellSelected(false);
      }}
      fullWidth
      maxWidth='sm'
      backgroundColor='#EFEDE7'
      renderFooter={<div></div>} // this minimizes the bottom padding.
    >
      <div>
        <Typography
          type='display-100'
          className='text-forest-primary mb-4 max-w-[436px] text-center'
        >
          {/* <>Upgrade now to build your ceremony for {discount}% Off</> */}
          <>Get up to 50% off of premium features when you upgrade now!</>
        </Typography>
        <div className='upsell-modal-cards-container'>
          {upsell && (
            <UpsellCard
              basePlaneTitle={baseProduct?.name || ''}
              title={upsell.name}
              price={19.99}
              originalPrice={originalDifference}
              attributes={attributes[upsell.name as keyof typeof attributes]}
              selected={upsellSelected}
              onSelect={() => {
                setUpsellSelected((prev) => !prev);
              }}
            />
          )}
          {addons.sort((a,b) => a.order - b.order).map((addon) => {
            return (
              <AddonCard
                key={addon.identifier}
                identifier={addon.identifier}
                selected={
                  selectedAddons.find(
                    (selectedAddon) => selectedAddon === addon.identifier
                  )
                    ? true
                    : false
                }
                onSelect={() => {
                  if (
                    selectedAddons.find(
                      (selectedAddon) => selectedAddon === addon.identifier
                    )
                  ) {
                    setSelectedAddons(
                      selectedAddons.filter(
                        (selectedAddon) => selectedAddon !== addon.identifier
                      )
                    );
                  } else {
                    setSelectedAddons([...selectedAddons, addon.identifier]);
                  }
                }}
              />
            );
          })}
        </div>
        <div className={`mt-4 flex justify-center gap-4`}>
          <Button
            onClick={() => {

              if (upsell && upsellSelected) {
                setUpsoldPlan(upsell); //This triggers place order when upsoldPlan is set
                return;
              }

              if (baseProduct) {
                handlePlaceOrder(baseProduct);
                return;
              }

              // setUpsellModalOpen(false);
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpsellModal;
