import { User } from '../../../api/auth';
import { Order, OrderSources } from '../../../api/paywall';

export type ButtonDetails = {
  redirect: string;
  text: string;
};

export const getButtonDetails = (
  isOrdination: boolean,
  order?: Order,
  status?: boolean,
  orderSource?: OrderSources,
  user?: User
): ButtonDetails => {
  let redirect = '/dashboard';
  let text = 'Go to dashboard';

  if (!user) {
    return {
      redirect: 'https://www.provenance.co/',
      text: 'Back to Homepage',
    };
  }

  if (!order || !order.ceremony_id) {
    return {
      redirect,
      text,
    };
  }

  if (isOrdination) {
    if (!status) {
      return {
        text: 'Return to Officiant Package',
        redirect: `/ceremony/${order?.ceremony_id || '0'}/ordained`,
      };
    } else {
      return {
        text: 'Access Now',
        redirect: `/ceremony/${order?.ceremony_id || '0'}/ordained`,
      };
    }
  }

  if (
    order &&
    order.payment_type &&
    (order.payment_type === 'wp_pay_per_ceremony' ||
      order.payment_type === 'subscription')
  ) {
    return {
      text: 'Go to dashboard',
      redirect: `/ceremony/planner/dashboard`,
    };
  }

  if (order && order.type === 'guest_toast_builder') {
    return {
      text: 'Generate your toast',
      redirect: `/ceremony/${order.ceremony_id}/toast?generate=true`,
    };
  }

  if (!orderSource) {
    return {
      redirect,
      text,
    };
  }

  switch (orderSource) {
    case OrderSources.merch_wt_editor: {
      text = 'Back to Welcome Toast Editor';
      redirect = `/welcome-toast-builder-ai/${order.ceremony_id}`;
      break;
    }
    case OrderSources.merch_cb_editor: {
      text = 'Back to Ceremony Builder Editor';
      redirect = `/ceremony-designer/${order.ceremony_id}`;
      break;
    }
    case OrderSources.merch_vb_editor: {
      text = 'Back to your Vows';
      redirect = `/vow-builder-ai/${order.ceremony_id}`;
      break;
    }
    case OrderSources.merch_tb_editor:
    case OrderSources.tb_editor: {
      text = 'Back to your Toasts';
      redirect = `/ai-toast-builder/${order.ceremony_id}`;
      break;
    }
    case OrderSources.merch_vb_landing:
    case OrderSources.vb_landing: {
      text = 'Back to your Vows';
      redirect = `/ceremony/${order.ceremony_id}/vowbuilder`;
      break;
    }
    case OrderSources.merch_tb_landing:
    case OrderSources.tb_landing: {
      text = 'Back to your Toasts';
      redirect = `/ceremony/${order.ceremony_id}/guest`;
      break;
    }
    case OrderSources.cb_landing_idos:
    case OrderSources.cb_landing_officiants:
    case OrderSources.cb_landing_pronouncement:
    case OrderSources.cb_landing_vows: {
      text = 'Back to the Ceremony Builder';
      redirect = `ceremony-designer/${order.ceremony_id}`;
      break;
    }
    case OrderSources.pmc: {
      text = 'Open Decks';
      redirect = `/ceremony/${order.ceremony_id}/pmc`;
      break;
    }
    default:
      break;
  }

  return {
    redirect,
    text,
  };
};

export const getMainText = (
  status: boolean,
  isGiftcard: boolean,
  isMerchandising: boolean,
  isOrdination: boolean,
  orderSource?: OrderSources
) => {
  if (!status) {
    //Abandon Cart
    if (isOrdination) {
      return `On the fence about purchasing our All-in-One Officiant Package? Use code 10BACK today for 10% off the package used by hundreds of thousands of officiants and couples to craft beautiful wedding ceremonies.`;
    } else {
      return `Still on the fence about purchasing the #1 Wedding Writing Tool? Use code 10BACK today for 10% off the tool used by hundreds of thousands of couples, officiants, guests, and wedding professionals.`;
    }
  } else {
    //Payment successful
    if (isGiftcard) {
      return `Your payment has been successfully processed. Your recipients will now have access to Provenance's full suite of digital tools to write a personalized ceremony with heartfelt vows.`;
    } else if (isMerchandising) {
      return `Thank you for your purchase. Keep an eye on your inbox ~ we’ll send you the survey link shortly.`;
    } else if (isOrdination) {
      return `Your credentials and resources can now be accessed immediately both within your dashboard and within the email you just received.`;
    }

    if (orderSource == OrderSources.pmc) {
      return `Your payment has been successfully processed. You can now access your Communication Card Decks.`;
    }

    return `Your payment has been successfully processed. You and your invitees now have access to everything listed in your plan. Enjoy the journey! `;
  }
};

export const getTitleText = (status: boolean, isOrdination: boolean) => {
  if (!status) {
    //Abandon cart
    if (isOrdination) {
      return 'Payment Unsuccessful';
    } else {
      return 'Not Ready To Checkout Yet?';
    }
  } else {
    //Payment successful
    if (isOrdination) {
      return 'Access Granted';
    } else {
      return 'Payment Successful';
    }
  }
};
